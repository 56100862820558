<template>
  <div class="companyUsers">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Users </CBreadcrumbItem>
    </teleport>

    <CCard>
      <CCardBody>
        <div class="d-flex mb-3 flex-wrap">
          <CRow class="gx-0 gx-lg-3 justify-content-start w-100">
            <CCol
              class="GCFilterMaxWidth col-12 col-md-12 col-lg-9 col-xl-10 col-xxl-10"
            >
              <GCFilter
                component="Users"
                :except-column="['company_name']"
                @search="(val) => onTimeoutSearch(() => fetchData(val))"
              />
            </CCol>
            <CCol
              class="col-12 col-md-12 col-lg-3 col-xl-2 col-xxl-2 text-lg-center mt-3 mt-lg-0 bulkAction"
            >
              <CDropdown
                :key="`${canSendEmail}-${selectedUsers.length}`"
                color="primary"
                style="margin-right: 10px; margin-bottom: 10px"
              >
                <CDropdownToggle
                  color="primary"
                  variant="outline"
                  :disabled="!selectedUsers.length"
                >
                  Bulk Action
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem
                    @click="handleSendEmail"
                  >
                    Send Communication
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CCol>
          </CRow>
        </div>

        <SmartTable
          :items="users"
          :items-per-page="parseInt(perPage)"
          :columns="fields"
          :sorter-value="sort"
          selectable="id"
          :selected-items="selectedUsers"
          :loading="loadingTable"
          :active-page="options.page"
          :per-page="options.per_page"
          :pages="options.last_page"
          :clear-text="
            options?.search?.length ? 'No search results found' : 'No Data yet'
          "
          @sorter-change="changeSort"
          @selected-items-change="onSelect"
          @update-active-page="(value) => onOptionChange('page', value)"
          @update-per-page="(value) => (options.per_page = value)"
        >

          <template #phone="{ item, column }">
            <a :href="'tel:' + item[column.key]">{{ item[column.key] }}</a>
          </template>

        </SmartTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
