import CInputDateRange from '@/components/CInputDateRange'
import table from '@/mixin/table'
import permissions from '@/utils/constants/permissions'
import GCFilter from '@/components/GCFilter/index'
import {OPEN_TASKS} from "@/domain/Entities/Task/taskStatuses";
import Gate from '@/services/Gate/Gate'
import {notifyByPlan} from '@/services/Notify/Toasts'

export default {
    components: {CInputDateRange, GCFilter},
    mixins: [table],
    inject: ['mountedComponent'],
    data() {
        return {
            users: [],
            fields: [
                {
                    key: 'full_name',
                    label: 'Full Name',
                    _style: '',
                    link: {
                        name: 'CompanyUser',
                        params: {user_id: 'id', id: 'company_id'},
                    },
                },
                {key: 'company_office_name', label: 'Office', _style: ''},
                {
                    key: 'projects_count',
                    label: 'Projects',
                    _style: '',
                    link: {
                        name: 'CompanyUserProjects',
                        params: {user_id: 'id', id: 'company_id'},
                    },
                    format: (value) => `${value}`,
                },
                {
                    key: 'tasks_count',
                    label: 'Open Tasks',
                    _style: '',
                    link: {
                        name: 'CompanyUserTasks',
                        params: {user_id: 'id', id: 'company_id'},
                        query: {status: OPEN_TASKS},
                    },
                    format: (value) => `${value}`,
                },
                {
                    key: 'overdue_tasks_count',
                    label: 'Overdue Tasks',
                    _style: '',
                    link: {
                        name: 'CompanyUserTasks',
                        params: {user_id: 'id', id: 'company_id'},
                        query: {status: 'Overdue'},
                    },
                    format: (value) => `${value}`,
                },
                {key: 'email', label: 'Email', _style: '', email: true},
                {key: 'phone', label: 'Phone', _style: ''},
                {key: 'job_title', label: 'Job Title', _style: ''},
                {key: 'company_office_address', label: 'Address', _style: ''},
            ],
            selectedUsers: [],
            options: {
                per_page: 10,
                page: 1,
                last_page: 1,
                order_by: '',
                order: 'asc',
                company_id: this.$route.params.id,
            },
            sort: {column: '', state: 'asc'},
        }
    },
    computed: {
        canSendEmail() {
            return this.authUser.can(permissions.COMPANIES_COMMUNICATION)
        },
    },
    methods: {
        onSelect(users) {
            this.selectedUsers = users
            this.$emit('onSelect', this.selectedUsers)
        },
        async handleSendEmail() {
            const check = await Gate.can('communication', 'employee')
            if (!check) {
                notifyByPlan()
                return false
            }
            this.$router.push({
                name: 'SendCommunication',
                query: {
                    users: this.selectedUsers
                },
            })
        },
        onOptionChange(option, value) {
            this.options[option] = value
            this.fetchData()
        },
        changeSort(val) {
            this.options.order_by = val.column
            this.options.order = val.state
            this.sort = val
            this.fetchData()
        },
        fetchData(f = {}) {
            this.loadingTable = true
            this.users = []
            this.$http.companies
                .getCompanyAllUsers({params: {...f, ...this.options}})
                .then((res) => {
                    this.key++
                    this.selectedUsers = []
                    this.users = res.data.data
                    this.options.last_page = res.data.meta.last_page
                    this.loadingTable = false
                })
                .finally(() => (this.loadingTable = false))
        },
        setCheck(value) {
            this.selectedUsers = value.map((i) => i.id)
        },
    },
}
